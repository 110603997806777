.Footer {
  background: var(--color-black-100);
  color: var(--color-white);
  font-size: 24px;
  line-height: 1.3;
}

.LinksList {
  list-style-type: none;
  padding: 0;
}

.Verybottom {
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-white);
}
