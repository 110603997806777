.Button {
  display: inline-flex;
  position: relative;
  border-radius: 999px;
  line-height: 1;
  font-weight: 500;
  background: transparent;
  color: var(--button-color);
  align-items: center;
  justify-content: center;
}

.outer {
  position: absolute;
  inset: -2px;
  border-radius: 999px;
  z-index: 0;
  background-image: linear-gradient(
    217.01deg,
    rgba(255, 255, 255, 0.2) 1%,
    rgba(255, 255, 255, 0) 53.47%,
    rgba(255, 255, 255, 0.2) 98.81%
  );
  transition: background-image 150ms ease-out, inset 150ms ease-out;
}

.Button:hover .outer {
  inset: -6px;
}

.inner {
  position: absolute;
  inset: 0;
  border-radius: 999px;
  z-index: 1;
  background-color: var(--button-background-color);
}

.text {
  z-index: 2;
}

.size--default {
  padding: 20px 32px;
  font-size: 20px;
}

.size--small {
  padding: 16px 24px;
  font-size: 14px;
  letter-spacing: 0.01em;
}
